<!--
 * @Descripttion: 用户主页
 * @version: 1.0
 * @Author: Niklaus
 * @Date: 2021-08-23 15:08:47
-->

<template>
<div class="common-layout">
  <el-container>
    <el-aside width="200px">
      <menu-side></menu-side>
    </el-aside>
    <el-container class="el-container is-vertical">
      <el-header>

        <el-menu mode="horizontal">
          <el-submenu class="right_top_menu" index="1">
            <template slot="title">
              <el-avatar :size="40"><i class="el-icon-s-custom"></i></el-avatar>
              <p class="user">欢迎您，{{ username }}</p>
            </template>
            <el-menu-item class="loginOut" @click="storeEdit = true">{{storeName}}</el-menu-item>
            <el-menu-item class="loginOut" @click="usernameEdit = true">修改帐号</el-menu-item>
            <el-menu-item class="loginOut" @click="passwordEdit = true">修改密码</el-menu-item>
            <el-menu-item class="loginOut" @click="loginOut">退出</el-menu-item>
          </el-submenu>
        </el-menu>
      </el-header>
      <el-main>
        <router-view class="router-view" />
      </el-main>
    </el-container>

    <!-- 修改帐号 dialog  -->
    <el-dialog title="修改帐号" :visible.sync="usernameEdit" width="30%" center @closed="onClosed">
      <el-form :model="usernameForm" ref="usernameForm" :rules="usernameFormRules" label-width="80px" :inline="false" size="normal">
        <el-form-item label="用户名">
          <el-input v-model="username" disabled readonly></el-input>
        </el-form-item>
        <el-form-item label="新用户名" prop="username">
          <el-input v-model="usernameForm.username"  placeholder="请输入手机号码"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="usernameForm.password" type="password" placeholder="请输入密码"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="usernameEdit = false">取消</el-button>
        <el-button type="primary" @click="handleChangeUsername">确认修改</el-button>
      </span>
    </el-dialog>

    <!-- 修改密码 dialog  -->
    <el-dialog title="修改密码" :visible.sync="passwordEdit" width="30%" center @closed="onClosed">
      <el-form :model="form" ref="form" :rules="rules" label-width="80px" :inline="false" size="normal">
        <el-form-item label="旧密码" prop="oldpassword">
          <el-input v-model="form.oldpassword" type="password" placeholder="请输入旧密码"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="password">
          <el-input v-model="form.password" type="password" placeholder="请输入新密码，由8位数字、字母和特殊符号组成!"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmpassword">
          <el-input v-model="form.confirmpassword" type="password" placeholder="请再次输入新密码"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="passwordEdit = false">取消</el-button>
        <el-button type="primary" @click="handleEdit">确认修改</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="修改企业信息"
      :visible.sync="storeEdit"
      width="40%"
      center
      @closed="onClosed"
    >
      <el-form :model="storeInfo" ref="storeInfo" :rules="storeRules" label-width="200px" :inline="false" size="normal">
        <el-form-item label="企业名称" >
          <el-input v-model="storeInfo.storeName"  placeholder="请输入企业名称" disabled></el-input>
        </el-form-item>
        <el-form-item label="统一社会信用代码" >
          <el-input v-model="storeInfo.uscc"  placeholder="请输入统一社会信用代码" disabled></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="linkMan" >
          <el-input v-model="storeInfo.linkMan"  placeholder="请输入联系人"></el-input>
        </el-form-item>
        <el-form-item label="联系人手机号" prop="linkPhone">
          <el-input v-model="storeInfo.linkPhone"  placeholder="请输入联系人手机号"></el-input>
        </el-form-item>
        <el-form-item label="联系地址"  prop="linkAddr">
          <el-input v-model="storeInfo.linkAddr"  placeholder="请输入联系地址"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="storeEdit = false">取消</el-button>
        <el-button type="primary" @click="handleStoreEdit">确认修改</el-button>
      </span>
    </el-dialog>

  </el-container>
  </div>
</template>

<script>
import MenuSide from "@/components/MenuSide"
import { updatePassword, changeUsername, loginout } from '@/utils/api'
import { checkPassword, checkUsername, checkConfirmPassword } from '@/utils/validator'
import {httpPost} from "@/utils/http"
import global from "@/common/global"

export default {
  name: 'Home',
  data(){
    return {
      username: '',
      storeName:'',
      storeInfo:{},
      passwordEdit: false,
      form: {
        oldpassword: '',
        password: '',
        confirmpassword: ''
      },
      usernameForm: {
        username: '',
        password: ''
      },
      usernameFormRules: {
        username: [
          {required: true, message: '请输入新的用户名', trigger: 'blur'},
          { validator: checkUsername, trigger: 'blur'  }
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
        ],
      },
      rules: {
        oldpassword: [
          {required: true, message: '请输入密码', trigger: 'blur'},
        ],
        password: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { validator: checkPassword, trigger: 'blur'  }
        ],
        confirmpassword: [
          { required: true, message: '请确认新密码', trigger: 'blur' },
          { validator: this.handleConfirmPassword,trigger: 'blur'  }
        ],
      },
      storeEdit: false,
      usernameEdit: false,
      storeRules:{
        linkMan:[
          {required: true, message: '请输入联系人', trigger: 'blur'},
        ],
        linkPhone:[
          {required: true, message: '请输入联系人手机号', trigger: 'blur'},
          { validator: this.handlePhone, trigger: 'blur' }
        ],
        linkAddr:[
          {required: true, message: '请输入联系地址', trigger: 'blur'},
        ],
      },
      url:{
        updateEwStoreExternal: '/ewbid/store/tbEwStoreExternal/updateEwStoreExternal'
      }
    }
  },
  components: {
      MenuSide,
  },
  created(){
    this.username = this.$ls.get("userInfo").username
    this.storeName = this.$ls.get("storeInfo").storeName;
    this.storeInfo = this.$ls.get("storeInfo");
  },
  methods: {
    /**
     * @Description: 用户登出
     * @Author: Niklaus
     * @Date: 2022-08-18 15:43:05
     */
    loginOut(){
      loginout().then( ({data}) =>  {
        if(data.success) {
          this.$ls.remove("userInfo")
          this.$ls.remove("storeInfo")
          this.$ls.remove("token")
          this.$router.replace("/login")
        }else {
          this.$message.error(data.message)
        }
      })
    },

    /**
     * @Description: 修改用户名
     * @Author: Niklaus
     * @Date: 2022-08-25 18:23:18
     */
    handleChangeUsername() {
      this.$refs['usernameForm'].validate( valid => {
        if(valid) {
          changeUsername({...this.usernameForm, id: this.$ls.get("userInfo").id}).then(res => {
            if(res.data.success) {
              this.$message.success('修改成功')
              this.usernameEdit = false
              this.$ls.set('token', res.data.result.token, 24 * 60 * 60 * 1000)
              this.$ls.set('userInfo', res.data.result.userInfo, 24 * 60 * 60 * 1000)
              this.username = this.$ls.get("userInfo").username
            }else
              this.$message.error(res.data.message)
          }).catch(_ => this.$message.error('修改错误'))
        }
      })
    },

    /**
     * @Description: 用户修改密码
     * @Author: Niklaus
     * @Date: 2022-08-18 15:43:17
     */
    handleEdit() {
      this.$refs['form'].validate( valid => {
        if(valid) {
          updatePassword({username:this.username, ...this.form}).then(res => {
            if(res.data.success) {
              this.$message.success('修改成功')
              this.passwordEdit = false
            }else
              this.$message.error(res.data.message)
          }).catch( _ => this.$message.error('修改失败') )
        }
      })
    },

    /**
     * @Description: 确认新密码
     * @Author: Niklaus
     * @Date: 2022-08-17 15:45:24
     */
    handleConfirmPassword(rule, value, callback) {
      let password = this.form['password']
      checkConfirmPassword({...rule, password}, value, callback)
    },

    /**
     * @Description: 修改密码框关闭后的回调
     * @Author: Niklaus
     * @Date: 2022-08-18 15:07:44
     */
    onClosed() {
      Object.keys(this.form).forEach( key => this.form[key] = '')
      Object.keys(this.usernameForm).forEach(key => this.usernameForm[key] = '');
    },
    //用户修改企业信息
    handleStoreEdit() {
      this.$refs['storeInfo'].validate( valid => {
        if(valid) {
          httpPost(global.gateway+this.url.updateEwStoreExternal,this.storeInfo).then(res=>{
            if(res.status=="200"&&res.data.success){
              //设置新的缓存
              this.$ls.set('storeInfo', this.storeInfo, 24 * 60 * 60 * 1000)
              this.$message.success('修改成功')
              this.storeEdit = false;
            }else{
              this.$message({ type: "error", message: res.data.message,duration:5000 });
            }
          }).catch(error=>{
            console.log(error)
          })
        }
      })
    },

    /**
     * @Description: 校验 统一社会信用代码
     * @Author: Niklaus
     * @Date: 2022-08-17 16:10:34
     */
    handlePhone(rule,value, callback) {
      let reg=/^1[3456789]\d{9}$/
      if(!reg.test(value))
        callback(new Error('请输入正确的手机号!'))
      else
        callback()
    },


  }
}

</script>

<style lang="scss" scoped>
  .el-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    min-width: 0
  }
  .el-container.is-vertical {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .el-main {
    display: block;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    overflow: auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #F5F5F5;
    padding: 20px;
  }
  body > .el-container {
    margin-bottom: 40px;
  }
  .el-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 50px;
    line-height: 50px;

  }
  .el-aside {
    height: 100vh;
    overflow-y: auto;
  }

  .arrow-ani {
    transform: rotate(180deg);
  }

  .router-view {
    background-color: #fff;
    height: 100%;
    border-radius: 10px;
    padding: 15px;
  }
  .el-icon-s-custom {
    font-size: 20px;
    margin-right: 0;
  }
  .user {
    color: #1e90ff;
    margin: 0 15px;
    font-size: 16px;
    cursor: pointer;
  }
  .right_top_menu .el-submenu__title {
    display: flex;
    align-items: center;
  }
  .loginOut {
    text-align: center;
    color: #1e90ff;
    line-height: 3em;
    padding: 30px 0;
    border-bottom: 1px solid #f1f2f6;
  }
</style>
