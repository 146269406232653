<!--
 * @Descripttion:
 * @version: 1.0
 * @Author: Niklaus
 * @Date: 2021-08-23 15:41:43
-->
<template>
<div>

  <h5 class="side-title">智采竞价系统</h5>
    <el-menu
      class="el-menu-vertical-demo"
      text-color="#A6A6A6"
      active-text-color="#00CCB2">

      <el-menu-item
        :key="index"
        :index="index.toString()"
        v-for="(menu,index) in menuList"
        @click="$router.push(menu.routePath)">
        <i :class="menu.iconClass"></i>
        <template #title>
          <span>{{ menu.name }}</span>
        </template>
      </el-menu-item>
    </el-menu>
</div>
</template>

<script>
export default {
  name: 'MenuSide',
  data(){
    return {
      menuList: [
        {name: '进行的项目', routePath: '/onGoing',iconClass: 'el-icon-chat-dot-square'},
        {name: '成交的项目', routePath: '/deal',iconClass: 'el-icon-mobile'},
        {name: '参加的项目', routePath: '/participate',iconClass: 'el-icon-connection'},
        {name: '绑定采购人', routePath: '/bind',iconClass: 'el-icon-link'},
        // {name: '测试扫码', routePath: '/testScan',iconClass: 'el-icon-link'},
        {name: '操作员管理', routePath: '/user',iconClass: 'el-icon-user'},

      ]
    }
  },

  created() {
    /** 判断是否为 管理员 */
    if(this.$ls.get("storeInfo")?.roleCode == '20')
      this.menuList.pop()
  }
}
</script>

<style scoped>
.side-title {
  color: #00CCB2;
  font-size: 20px;
  text-align: center;
  line-height: 50px;
  line-height: 50px;
}
.el-menu {
  border-right: 0;
}
</style>